<template>
  <b-row>
    <b-col sm="12" md="10" lg="8">
      <b-tabs
        pills
      >
        <!-- general tab -->
        <b-tab active>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t('General') }}</span>
          </template>
    
          <account-setting-general
            v-if="userPrepare"
            :general-data="user"
          />
        </b-tab>
        <!--/ general tab -->
    
        <!-- change password tab -->
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="LockIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t('Cambiar contraseña') }}</span>
          </template>
    
          <account-setting-password />
        </b-tab>
        <!--/ change password tab -->
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'

export default {
  components: {
    AccountSettingGeneral,
    AccountSettingPassword,
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getUser',
      user: 'users/getUser',
    }),
    userPrepare() {
      if (Object.keys(this.user).length > 0) {
        return true
      }
      return false
    },
  },
  data() {
    return {
      options: {},
    }
  },
  methods: {
    ...mapActions({
      getUser: 'users/getUser',
    }),
  },
  async created() {
    await this.getUser(this.currentUser.id)
  },
}
</script>
