<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="editUser">
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="6" class="mb-2 auth-create-form">
            <ImageDropzone ref="images" :files="files" />
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('Nombre')" label-for="account-name">
              <b-form-input
                v-model="user.name"
                name="name"
                :placeholder="$t('Nombre')"
              />
            </b-form-group>
            <b-form-group :label="$t('Usuario')" label-for="account-username">
              <b-form-input
                v-model="user.surname"
                :placeholder="$t('Usuario')"
                name="username"
              />
            </b-form-group>
            <b-form-group :label="$t('Telefono')" label-for="account-phone">
              <b-form-input
                v-model="phone"
                name="company"
                :placeholder="$t('Telefono')"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('NombreUsuario')"
              label-for="account-username"
            >
              <b-form-input
                v-model="user.username"
                :placeholder="$t('NombreUsuario')"
                name="username"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              name="Email"
              :rules="{ required, email }"
            >
              <b-form-group :label="$t('Email')" label-for="account-e-mail">
                <b-form-input
                  v-model="user.email"
                  name="email"
                  :placeholder="$t('Email')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button type="submit" variant="primary" class="mt-2 mr-1">
              {{ $t("Guardar") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions } from "vuex";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone";
import { ref } from "@vue/composition-api";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ImageDropzone,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      files: [],
      user: this.generalData,
      profileFile: null,
      required,
      email,
    };
  },
  computed: {
    phone: {
      get() {
        if (
          this.user &&
          (this.user.phone == null || this.user.phone === "null")
        ) {
          return "";
        } else {
          return this.user.phone;
        }
      },
      set(val) {
        this.user.phone = val;
        return val;
      },
    },
  },
  created() {
    let avatar = {
      name: "Avatar",
      url: process.env.VUE_APP_IMAGE_PLACEHOLDER,
      urlDelete: `/users/delete_image/${this.generalData.id}`,
    };
    if (this.generalData.avatar !== null) {
      avatar.url = this.generalData.avatar;
    }
    this.files = [avatar];
    this.user = this.generalData;
  },
  methods: {
    ...mapActions({
      edit: "users/edit",
    }),
    resetForm() {
      this.user = JSON.parse(JSON.stringify(this.generalData));
    },
    handleSubmit() {
      this.$refs.editUser.validate().then((success) => {
        if (success) {
          const { id } = this.user;
          const formData = this.createFormData();
          if (formData) {
            this.edit({ id, user: formData });
          }
        }
      });
    },
    createFormData() {
      const data = this.$refs.images.getFormData();

      data.append("name", this.user.name);
      data.append("email", this.user.email);
      data.append("phone", this.user.phone);
      data.append("surname", this.user.surname);
      data.append("lang", this.user.lang);

      return data;
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
